import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import SplashPage from "./components/SplashPage/SplashPage.lazy";
import LanguageSelectionPage from "./components/LanguageSelectionPage/LanguageSelectionPage.lazy";
import InformationPage from "./components/InformationPage/InformationPage.lazy";
import "./App.css";
import CategorySelectionPage from "./components/CategorySelectionPage/CategorySelectionPage";
import { useEffect } from "react";
import PdfViewerPage from "./components/PdfViewerPage/PdfViewerPage.lazy";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the "fromApp" query parameter is present
    const params = new URLSearchParams(location.search);
    const isFromApp = params.get("fromTotemApp");

    if (isFromApp) {
      const today = new Date();
      const targetDate = new Date(2024, 8, 11);
      if (
        today.getDate() === targetDate.getDate() &&
        today.getMonth() === targetDate.getMonth() &&
        today.getFullYear() === targetDate.getFullYear()
      ) {
        navigate("/cartello-override");
      }
      
    }
  }, [location, navigate]);


  const getBackgroundImage = () => {
    switch (location.pathname) {
      case "/language":
        return 'bg-[url("/public/img/bg_language_selection.png")]';
      default:
        return 'bg-[url("/public/img/bg_pool.png")]';
    }
  };

  return (
    <div
      className={`relative w-full h-dvh bg-fixed bg-center bg-cover ${getBackgroundImage()}`}
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <Routes>
          <Route path="/" element={<SplashPage />} />
          <Route path="/language" element={<LanguageSelectionPage />} />
          <Route path="/select" element={<CategorySelectionPage />} />
          <Route path="/info" element={<InformationPage />} />
          <Route path="/cartello-override" element={<PdfViewerPage />} />
        </Routes>
      </div>
    </div>
  );
};

const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;
