import { useState, useEffect, useCallback } from 'react';

const useIdleTimer = (timeout: number, onIdle: () => void) => {
  const [isIdle, setIsIdle] = useState(false);

  const resetTimer = useCallback(() => {
    setIsIdle(false);
  }, []);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    const handleActivity = () => {
      clearTimeout(timer);
      timer = setTimeout(() => setIsIdle(true), timeout);
    };

    const events = ['mousemove', 'keypress', 'scroll', 'click'];

    events.forEach(event => window.addEventListener(event, handleActivity));

    handleActivity();

    return () => {
      clearTimeout(timer);
      events.forEach(event => window.removeEventListener(event, handleActivity));
    };
  }, [timeout, resetTimer]);

  useEffect(() => {
    if (isIdle) {
      onIdle();
    }
  }, [isIdle, onIdle]);
};

export default useIdleTimer;
