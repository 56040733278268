import React, { lazy, Suspense } from "react";

const LazyInformationPage = lazy(() => import("./InformationPage"));

const InformationPage = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => (
  <Suspense fallback={null}>
    <LazyInformationPage {...props} />
  </Suspense>
);

export default InformationPage;
