import React, { lazy, Suspense } from "react";

const LazyPdfViewerPage = lazy(() => import("./PdfViewerPage"));

const PdfViewerPage = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => (
  <Suspense fallback={null}>
    <LazyPdfViewerPage {...props} />
  </Suspense>
);

export default PdfViewerPage;
