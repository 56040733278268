import React, { lazy, Suspense } from "react";

const LazySplashPage = lazy(() => import("./SplashPage"));

const SplashPage = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => (
  <Suspense fallback={null}>
    <LazySplashPage {...props} />
  </Suspense>
);

export default SplashPage;
