import { FC } from "react";
import { CategorySelectionPageWrapper } from "./CategorySelectionPage.styled";
import { useNavigate } from "react-router-dom";
//import { Language } from "../../Types";
import useIdleTimer from "../../hooks/IdleTimerHook";

interface CategorySelectionPageProps {}

const CategorySelectionPage: FC<CategorySelectionPageProps> = () => {
  
  //const { state } = useLocation();
  const navigate = useNavigate();
  
  // Extract language and assert it as possibly undefined
  //const language: string | undefined = (state as { language?: string })?.language;
  //const validLanguage: Language = (['it', 'en', 'fr', 'de'].includes(language as Language)) ? (language as Language) : 'it';
  
  useIdleTimer(2 * 60 * 1000, () => {
    navigate('/');
  });

  return (
    <CategorySelectionPageWrapper data-testid="CategorySelectionPage">
      
    </CategorySelectionPageWrapper>
  );
};

export default CategorySelectionPage;
